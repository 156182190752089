.cardTitle {
  width: 15.313rem;
  height: 1.5rem;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans', sans-serif;
  font-weight: 700;
  color: #333333;
  text-align: left;
  line-height: normal;
  margin-bottom: 1rem;
}

.inputTitle {
  width: 11.25rem;
  height: 0.875rem;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
  color: #333333;
  text-align: left;
  line-height: normal;
}

.cardMandant {
  width: 46rem;
  height: 15.875rem;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem;
  border: 0.063rem solid rgba(215, 215, 215, 0.5);
  background-color: #ffffff;
  box-sizing: border-box;
  display: table;
  margin-right: auto;
  margin-left: auto;
}
